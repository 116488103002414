import { auth, db } from "../../utils/firebase";
import { doc, updateDoc } from "firebase/firestore";

import { AiOutlineLeft } from "react-icons/ai";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useProjectContext } from "../../contexts/ProjectContext";

function SelectProject() {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const projectContext = useProjectContext();

  useEffect(() => {
    if (user === null) return navigate("/");
  }, [user, navigate]);

  const selectedClassName =
    "border-2 border-blue-500 p-4 rounded-xl cursor-pointer";
  const regularClassName = "border-2 p-4 rounded-xl cursor-pointer";

  const onSelectProject = (id: string) => {
    const docRef = doc(
      db,
      `/prod/_projects/participating_teams/${user!.email}`
    );
    updateDoc(docRef, { lastSelectedProject: id }).then(() => {
      navigate("/home");
    });
  };
  if (!projectContext) return <></>;

  return (
    <div className="h-screen flex flex-col">
      <div className="px-4 py-8 flex items-center space-x-1">
        <div className="cursor-pointer" onClick={() => navigate("/")}>
          <AiOutlineLeft className="text-2xl" />
        </div>
        <h1 className="text-3xl font-bold">Select Project</h1>
      </div>
      <div className="px-4 space-y-4 mb-4 overflow-y-scroll">
        {projectContext.projects.map((p) => (
          <div
            key={p.id}
            className={
              projectContext.lastSelectedProject === p.id
                ? selectedClassName
                : regularClassName
            }
            onClick={() => onSelectProject(p.id)}
          >
            <div
              className="h-2 w-2/3 rounded mb-1"
              style={{ backgroundColor: p.color }}
            ></div>
            <div>
              <p className="text-xl">{p.projectName}</p>
              <p className="text-sm text-gray-400">{p.courseName}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SelectProject;
