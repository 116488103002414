import React, { useEffect, useState } from "react";
import {
  SmirkResponseType,
  checkResponseRequireInformation,
  getDefaultResponseObjectArray,
  submitResponse,
} from "./ResponseType";
import { delay, useQuery } from "../../utils";

import EvalLayout from "../../components/EvalLayout";
import { FiArrowLeft } from "react-icons/fi";
import { IoMdInformationCircleOutline } from "react-icons/io";
import RateTable from "./RateTable";
import SubmitButton from "../../components/SubmitButton";
import Survey from "./Survey";
import { auth } from "../../utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEvalContext } from "../../contexts/EvalContext";
import { useNavigate } from "react-router-dom";
import { useOverviewContext } from "../../contexts/OverviewContext";
import { useProjectContext } from "../../contexts/ProjectContext";
import { isCommentRequired, useCourseConfigContext } from "../../contexts/CourseConfigContext";

const EvalHeader: React.FC<{
  onBack: () => void;
  onShowGuide: () => void;
}> = ({ onBack, onShowGuide }) => {
  return (
    <>
      <FiArrowLeft className="text-3xl text-black mr-2" onClick={onBack} />
      <h1 className="text-3xl font-bold grow">Rate My Peers</h1>
      <IoMdInformationCircleOutline
        className="text-3xl text-blue-500"
        onClick={onShowGuide}
      />
    </>
  );
};

const Eval = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [user] = useAuthState(auth);
  const overviewContext = useOverviewContext();
  const evalCx = useEvalContext();
  const [userResponse, setUserResponse] = useState<SmirkResponseType[]>([]);
  const [showSurvey, setShowSurvey] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const overviewCx = useOverviewContext();
  const projectCx = useProjectContext();
  const [provideComments, setProvideComments] = useState(false);
  const courseConfig = useCourseConfigContext();

  useEffect(() => {
    if (projectCx && !projectCx.lastSelectedProject) {
      navigate("/selectProject");
    }
  });

  useEffect(() => {
    const sid = query.get("sid");
    if (evalCx.evalDoc && !sid) {
      return navigate("/");
    } else if (!evalCx.evalDoc && sid) {
      return navigate("/");
    } else if (evalCx.evalDoc && sid && evalCx.evalDoc.id !== sid) {
      return navigate("/");
    } else if (!evalCx.targetDate) {
      return navigate("/");
    }
  }, [evalCx.evalDoc, query, evalCx, navigate]);

  useEffect(() => {
    if (!user) return;
    if (evalCx.loading) return;
    if (evalCx.evalDoc) {
      setUserResponse(evalCx.evalDoc.response);
      evalCx.evalDoc.response.forEach((v, i) => {
        if (!isCommentRequired(courseConfig, v.rating) && v.comment) {
          setProvideComments(true);
        }
      });
    } else {
      const members = overviewContext.team?.members || [];
      setUserResponse(
        getDefaultResponseObjectArray(
          // members.filter((x) => x.email !== user.email),
          members,
          courseConfig?.default!
        )
      );
    }
  }, [overviewContext.team, user, evalCx.loading, evalCx.evalDoc]);

  useEffect(() => {
    if (user === null) return navigate("/");
  }, [user, navigate]);

  const setRatings = (ratings: number[]) => {
    const newUserResponse = userResponse.map((value, index) => {
      const result: SmirkResponseType = { ...value };

      if (result.rating !== ratings[index]) {
        result.participation = [];
        result.collaboration = [];
        result.contributions = [];
        result.comment = "";
        result.private_comment = "";
      }
      result.rating = ratings[index];
      return result;
    });
    setUserResponse(newUserResponse);
  };

  const onSubmit = async () => {
    if (!projectCx) return;
    const requireInfo = checkResponseRequireInformation(courseConfig!, userResponse);

    if (requireInfo || provideComments) {
      setShowSurvey(true);
    } else {
      setIsSubmitting(true);
      await delay(500);
      submitResponse(
        evalCx.evalDoc ? evalCx.evalDoc.id : "",
        user?.email!,
        projectCx.lastSelectedProject,
        overviewCx.team?.teamName!,
        userResponse,
        evalCx.targetDate!
      )
        .then(() => {
          navigate("/success");
          setIsSubmitting(false);
        })
        .catch((e) => {
          // error occurred
          console.log(e);
          setIsSubmitting(false);
        });
    }
  };

  if (showSurvey) {
    return (
      <Survey
        response={userResponse}
        setResponse={setUserResponse}
        exitSurvey={() => setShowSurvey(false)}
        optionalComments={provideComments}
      />
    );
  }

  return (
    <EvalLayout
      header={
        <EvalHeader
          onBack={() => navigate("/home")}
          onShowGuide={() => navigate("/guidance")}
        />
      }
    >
      <div className="px-4 h-full flex flex-col">
        {/* Rating area */}
        <div className="h-0 grow overflow-scroll space-y-2">
          <RateTable
            user={user}
            members={overviewContext.team?.members || []}
            ratings={userResponse.map((x) => x.rating)}
            setRatings={setRatings}
          />
          <div
            className="flex space-x-2 py-2 items-start"
            onClick={() => setProvideComments(!provideComments)}
          >
            <input
              type="checkbox"
              checked={provideComments}
              onChange={(e) => setProvideComments(e.target.checked)}
            />
            <p className="-mt-[5px]">
              I would like to provide one or more optional comments.
            </p>
          </div>
        </div>
        {/* Submit button */}
        <div className="w-full py-4 h-24">
          <SubmitButton
            loading={isSubmitting}
            onClick={onSubmit}
            disabled={userResponse.length === 0}
          />
        </div>
      </div>
    </EvalLayout>
  );
};

export default Eval;
