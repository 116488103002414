import { SmirkResponseType } from "./ResponseType";
import { SurveyStep } from "./Survey";

type Props = {
  name: string;
  rating: number;
  step: SurveyStep;
  response: SmirkResponseType[];
  instructor_only: boolean;
  setResponse: (v: SmirkResponseType[]) => void;
};

const SurveyComment = ({
  name,
  step,
  response,
  instructor_only,
  setResponse,
}: Props) => {
  const onUpdateComment = (comment: string) => {
    const newResponse = [...response];
    if (instructor_only) {
      newResponse[step.mainStep].private_comment = comment;
    } else {
      newResponse[step.mainStep].comment = comment;
    }
    setResponse(newResponse);
  };

  return (
    <div className="space-y-1">
      {instructor_only ? (
        <>
          <h2 className="font-bold underline">Private Comments</h2>
          <h3 className="text-xs font-bold text-red-500">
            {" "}
            (Visible to instructor only)
          </h3>
        </>
      ) : (
        <>
          <h2 className="font-bold underline">Anonymous Comments</h2>
          <h3 className="text-xs font-bold">
            (Visible to {name} and instructor)
          </h3>
        </>
      )}
      <div className="h-32 border-gray-500 px-1">
        <textarea
          value={
            instructor_only
              ? response[step.mainStep].private_comment
              : response[step.mainStep].comment
          }
          onChange={(e) => onUpdateComment(e.target.value)}
          className="h-full w-full border-2 p-2"
          placeholder={
            instructor_only
              ? `ex. Any private comments for ${name} you want to share with the instructor`
              : `ex. Any anonymous comments you want to share with ${name}`
          }
        />
      </div>
    </div>
  );
};

export default SurveyComment;
